import { Map, GoogleApiWrapper } from "google-maps-react";
import React, { Component } from "react";

export class MapContainer extends Component {
    render() {
        const style = {
            width: "100%",
            height: "100%",
        };

        return (
            <Map
                google={this.props.google}
                zoom={15}
                style={style}
                center={{
                    lat: 52.854885,
                    lng: 4.3081807,
                }}
            ></Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyBtc5xLUeuK8ATMfE1--zSAMFD9YLK9kWM",
})(MapContainer);
