import React, { Component } from "react";
import proc from "../images/data_block.png";

class Data extends Component {
    render() {
        return (
            <div id="data">
                <img src={proc} alt="" className="data_image" />
            </div>
        );
    }
}

export default Data;
