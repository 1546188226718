import React, { Component } from "react";
import agga_tech_2 from "../images/agga_tech_yellow.png";

import AnchorLink from "react-anchor-link-smooth-scroll";

class Hero extends Component {
    componentDidMount() {
        window.addEventListener("scroll", function () {
            var header = document.querySelector("header");
            header.classList.toggle("sticky", window.scrollY > 0);
        });
    }

    render() {
        return (
            <div id="hero" className="hero-container">
                <img src={agga_tech_2} alt="" className="hero2" />
                <AnchorLink href="#about">
                    <div className="arrow"></div>
                </AnchorLink>
            </div>
        );
    }
}

export default Hero;
