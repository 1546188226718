import React from "react";

class Slider extends React.Component {
    state = { value: 50 };

    render() {
        return (
            <input
                type="range"
                min={0}
                max={255}
                value={50}
                className="slider"
            />
        );
    }
}

export default Slider;
