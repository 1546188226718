import React, { Component } from "react";
import proc from "../images/proc.png";

class Proc extends Component {
    render() {
        return (
            <div id="proc">
                <img src={proc} alt="" />
            </div>
        );
    }
}

export default Proc;
