import React, { Component } from "react";

class TradeHero extends Component {
    render() {
        return (
            <div id="hero" className="trade-hero-container">
                <div className="trade-hero-text">
                    <h2>Managed Trading Solutions</h2>
                    <p>
                        With best-in-class technology, security, apps, and
                        <br />
                        integrations, Agga Technologies kills it.
                    </p>
                    <div className="request-demo">
                        <a href="/#">
                            <h1>Request a Demo</h1>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default TradeHero;
