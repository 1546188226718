import React, { Component } from "react";
import binance_logo from "../images/binance_logo.svg";
import kraken_logo from "../images/kraken_logo.png";
import bots_logo from "../images/bots_logo.svg";
import huobi_logo from "../images/huobi_logo.svg";
import bitmex_logo from "../images/bitmex_logo.svg";

class Partners extends Component {
    render() {
        return (
            <div id="partners">
                <h1>Our Partners</h1>
                <div className="logo-container">
                    <img src={binance_logo} alt="" />
                    <img src={kraken_logo} alt="" />
                    <img src={bots_logo} alt="" />
                </div>
                <div className="logo-container">
                    <img src={huobi_logo} alt="" />
                    <img src={bitmex_logo} alt="" />
                </div>
            </div>
        );
    }
}

export default Partners;
