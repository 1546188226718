import React, { Component } from "react";
import agga_text_yellow from "../images/agga_text_yellow.png";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Link } from "react-router-dom";

class Header extends Component {
    componentDidMount() {
        window.addEventListener("scroll", function () {
            var header = document.querySelector("header");
            header.classList.toggle("sticky", window.scrollY > 0);
        });
    }
    render() {
        return (
            <header>
                <AnchorLink href="#hero" className="logo">
                    <Link to="/">
                        <img src={agga_text_yellow} alt="" />
                    </Link>
                </AnchorLink>
                <ul>
                    <li>
                        <AnchorLink href="#about">About</AnchorLink>
                    </li>
                    <li>
                        <AnchorLink href="#team">Team</AnchorLink>
                    </li>
                    <li>
                        <AnchorLink href="#contact">Contact</AnchorLink>
                    </li>
                </ul>
            </header>
        );
    }
}

export default Header;
