import React, { Component } from "react";
import "./css/default.css";
import "./css/media-queries.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import TradingSolutions from "./pages/TradingSolutions";
import ApiTrading from "./pages/ApiTrading";
import Home from "./pages/Home";

class App extends Component {
    render() {
        return (
            <Router>
                <div className="App">
                    <Switch>
                        <Route path="/" exact component={Home} />
                        <Route
                            path="/trading_solutions"
                            component={TradingSolutions}
                        />
                        <Route path="/api_trading" component={ApiTrading} />
                    </Switch>
                </div>
            </Router>
        );
    }
}

export default App;
