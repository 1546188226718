/* eslint-disable no-multi-str */
const data = {
    product1: {
        name: "API Trading Signals",
        tagline: "Alpha Over API",
        description:
            "Trading signals provide clients with precise trade \
            timings notifying users which asset(s) to \
            buy/sell/long/short and on what exchange to conduct \
            the transaction. A highly effective way to \
            distribute trade strategies as a subscription \
            service over API",
    },
    product2: {
        name: "Managed Trading Solutions",
        tagline: "All-in-one Trading Service",
        description:
            "Comprehensive managed trading solutions powered by our trading toolkit \
            which executes trades and manages all positions in a portfolio. \
            The trading toolkit comes with a bespoke dashboard that helps monitor performance, \
            holdings and infrastructure health.",
    },
    product3: {
        name: "Data Suite",
        tagline: "High Fidelity Crypto Data",
    },

    jesper: {
        name: "Jesper Maassen",
        title_short: "CIO",
        title_long: "Chief Investment Officer",
        linkedin: "https://www.linkedin.com/in/jesper-maassen-a1b4215b/",
    },
    mark: {
        name: "Mark Offerman",
        title_short: "CEO",
        title_long: "Chief Executive Officer",
        linkedin: "https://linkedin.com",
    },
    jasper: {
        name: "Jasper Hu",
        title_short: "Tech Lead",
        title_long: "Lead Developer",
        linkedin: "https://linkedin.com",
    },
    martin: {
        name: "Martin Georgiev",
        title_short: "Intern",
        title_long: "Quant Intern",
        linkedin: "https://linkedin.com",
    },
    sebastiaan: {
        name: "Sebastiaan Smits",
        title_short: "Intern",
        title_long: "Quant Intern",
        linkedin: "https://linkedin.com",
    },
};

export default data;
