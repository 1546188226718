import React, { Component } from "react";
import TeamCard from "../Components/TeamCard";

class Team extends Component {
    render() {
        if (this.props.data) {
            var data = this.props.data;
        }
        return (
            <div id="team">
                <div className="team-container">
                    <div className="team-cards">
                        <TeamCard data={data.mark} />
                        <TeamCard data={data.jesper} />
                        <TeamCard data={data.jasper} />
                    </div>
                    <div className="team-cards-2">
                        <TeamCard data={data.martin} />
                        <TeamCard data={data.sebastiaan} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Team;
