import React, { Component } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

class Footer extends Component {
  render() {
    return (
      <footer>
        <ul className="copyright">
          <li>&copy; Agga Technologies 2020</li>
        </ul>
        <div id="go-top">
          <AnchorLink href="#hero" className="up-arrow">
            ^
          </AnchorLink>
          <i className="icon-up-open"></i>
        </div>
      </footer>
    );
  }
}

export default Footer;
