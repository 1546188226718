import React, { Component } from "react";
import GoogleApiWrapper from "../Components/Map";

class Contact extends Component {
    render() {
        return (
            <div id="contact">
                <div className="map">
                    <GoogleApiWrapper />
                </div>
                <div className="details">
                    <h3>Agga Technologies B.V.</h3>

                    <h3>Nassaulaan 13, 2514JS</h3>
                    <h3>The Hague, The Netherlands</h3>

                    <h3>+31624524244</h3>
                    <h3>hello@aggatechnologies.com</h3>
                </div>
            </div>
        );
    }
}

export default Contact;
