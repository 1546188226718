import React, { Component } from "react";
import portrait from "../images/portrait.jpg";

class TeamCard extends Component {
    render() {
        if (this.props.data) {
            var data = this.props.data;
        }
        return (
            <div className="team-card-base">
                <div className="team-card">
                    <div className="team-image">
                        <img src={portrait} alt="" />
                    </div>
                </div>
                <div className="team-title">
                    <div>
                        <h3>{data.name}</h3>
                    </div>
                    <div>
                        <h4>{data.title_short}</h4>
                    </div>
                </div>
            </div>
        );
    }
}

export default TeamCard;
