import React, { Component } from "react";
import dashboard2 from "../images/dashboard2.png";
import Slider from "../Components/Slider";

class TradeAbout extends Component {
    render() {
        return (
            <div id="hero" className="trade-about-container">
                <div className="trade-about">
                    <h1> Customizable Strategy Portfolio </h1>
                    <p>- BENEFIT ONE IS THIS BENEFIT</p>
                    <p>- BENEFIT ONE IS THIS BENEFIT</p>
                    <p>- BENEFIT ONE IS THIS BENEFIT</p>
                    <p>- BENEFIT ONE IS THIS BENEFIT</p>
                </div>
                <div className="trade-image">
                    <img src={dashboard2} alt="" />
                </div>
                <div>
                    <Slider />
                </div>
            </div>
        );
    }
}

export default TradeAbout;
