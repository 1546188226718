import React, { Component } from "react";
import agga_tech_2 from "../images/dashboard.png";
import stacked_charts from "../images/stacked_charts.png";
import { Link } from "react-router-dom";

class About extends Component {
    render() {
        if (this.props.data) {
            var data = this.props.data;
        }

        return (
            <div id="about">
                <div className="card-container">
                    <div className="card-text">
                        <h2>{data.product2.name}</h2>
                        <p>{data.product2.description}</p>
                        <Link to="/trading_solutions">
                            <div className="learn-more">
                                <a href="/#">
                                    <h1>Learn More</h1>
                                </a>
                            </div>
                        </Link>
                    </div>
                    <img src={agga_tech_2} alt="" className="dashboard-icon" />
                </div>

                <div className="card-container-2">
                    <div>
                        <img
                            src={stacked_charts}
                            alt=""
                            className="dashboard-icon"
                        />
                    </div>
                    <div className="card-text">
                        <h2>{data.product1.name}</h2>
                        <p>{data.product1.description}</p>
                        <Link to="/api_trading">
                            <div className="learn-more">
                                <a href="/#">
                                    <h1>Learn More</h1>
                                </a>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default About;
