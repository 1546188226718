import React, { Component } from "react";
import Header from "../Components/Header";
import Hero from "../Components/Hero";

class ApiTrading extends Component {
    render() {
        return (
            <div>
                <Header />
            </div>
        );
    }
}

export default ApiTrading;
