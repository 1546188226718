import React, { Component } from "react";
import Header from "../Components/Header";
import About from "../Components/About";
import Contact from "../Components/Contact";
import Footer from "../Components/Footer";
import Hero from "../Components/Hero";
import Team from "../Components/Team";
import Proc from "../Components/Proc";
import Data from "../Components/Data";
import Partners from "../Components/Partners";
import data from "../data";

class Home extends Component {
    render() {
        return (
            <div>
                <Header />
                <Proc />
                <Hero />
                <About data={data} />
                <Data />
                <Team data={data} />
                <Partners />
                <Contact />
                <Footer />
            </div>
        );
    }
}

export default Home;
