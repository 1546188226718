import React, { Component } from "react";
import Header from "../Components/Header";
import TradeHero from "../Components/TradeSolutionsHero";
import TradeAbout from "../Components/TradeSolutionsAbout";

class TradingSolutions extends Component {
    render() {
        return (
            <div>
                <Header />
                <TradeHero />
                <TradeAbout />
            </div>
        );
    }
}

export default TradingSolutions;
